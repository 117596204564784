/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        
			
			$('document').ready(function(){
				
					
				$('.background').height($(window).height());
				$('#formular').children('.background').height($(window).height()*2);
				
				var one = $('#one').height();
				var two = $('#two').height();
				
				
				
				var three = $('#three').height();
				var four = $('#four').height();
				var five = $('#five').height();
				var six = $('#six').height();
				var seven = $('#seven').height();
				
				var offset = 0;
				
				
				$(window).scroll(function() {
					var scrollTop = $(window).scrollTop() + 20;
					
					var color = 'green';
					
		
					
					if(scrollTop < (one+two+three+four+five+six+seven+offset)){	
						// color = '#018D36'; Grön
						color = '#078035';
						if(scrollTop < (one+two+three+four+five+six+offset)){
							color = '#F9EA1A'; // blå
							if(scrollTop < (one+two+three+four+five+offset)){
							//	color = '#868686'; Grå								
								color = '#595959';
								if(scrollTop < (one+two+three+four+offset)){
								//	color = '#683B11'; brun
									color = '#5A3410';
									if(scrollTop < (one+two+three+offset)){
										color = '#078035';
										if(scrollTop < (one+two+offset)){
											color = '#1D6FB8' // blå
											if(scrollTop < one+offset){
											//	color = '#941C80'		lila
												color = '#7E186D';
											}
										}
									}
								}
							}
						}
					}
					
					if($('.nav').css('background') != color){
						$('.nav').css('background', color);
					}
				});
				
				$('#menu').height($('#menu').height()-140);
				
				$('#nav-toggle').click(function(){
				
					$('.open-nav').toggle();
					$('.close-nav').toggle();
				
					
					$('#menu').find('li').hide();
					$('#menu').toggle('100', function(){
						  $('#menu').find('li').fadeIn(100);	
					});
				});
				
				$('#menu').find('a').click(function(){
					$('.open-nav').toggle();
					$('.close-nav').toggle();
				
					
					$('#menu').find('li').hide();
					$('#menu').toggle('100', function(){
						  $('#menu').find('li').fadeIn(100);	
					});
				});
				
				
				
				/* One Page Nav */
				
							$('.page-scroll').click(function(){
							
							var el = $(this).attr('href');
							var elWrapped = $(el);
							
							scrollToDiv(elWrapped,-3);
							
							return false;
							
							});
							
							function scrollToDiv(element,navheight){

							var offset = element.offset();
							var offsetTop = offset.top;
							var totalScroll = offsetTop-navheight;
							
							$('body,html').animate({
							scrollTop: totalScroll
							}, 500);
							
							if( element['selector']  != '#gala-och-prisutdelning'){
								setTimeout(function(){						
									
										var y = $(window).scrollTop();  //your current y position on the page
	
								$('body,html').animate({
									scrollTop: y+$(window).height()
								}, 500);
	
								}, 1000);
							}
							
							} 


			/* One Page Nav END */
				
				
			
			});
        
       
 
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
